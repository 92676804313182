import { ZodEnum } from 'zod'
import { FN } from '@digital-magic/ts-common-utils'
import { MenuItemEntry } from './types'

export const buildEnumOptions = <T extends string>(
  enumSchema: ZodEnum<[T, ...Array<T>]>,
  buildLabel: FN<T, string>
): ReadonlyArray<MenuItemEntry<T>> =>
  enumSchema.options.map((value) => ({
    value: value,
    label: buildLabel(value)
    //label: t(`${labelPrefix}.${optionValue}` as const)
  }))
